export default function StdOutLogElement ({output}) {
    if (typeof(output)=="string" || typeof(output)=="object") {
        output = [output]
    }
    const formatLog = (log) => {

        if (typeof(log) === "object" && log.type === "cloudwatch") {
            let level = log.level || "info"

            return <span className={"log " + level}>{log.message}</span>
        }

        return log
    }

    const results = output.map((value) =>

        <div className="stdOutLogElement">
            { formatLog(value) }
        </div>
    );

    return (
        <div>
            {results}
        </div>
    );
} 