import Pipe from './Pipe'
import TextProcessor from './TextProcessor'
import Extract from './Extract'
import ExtractTable from './ExtractTable'
import Filter from './Filter'
import List from './List'
import Load from './Load'
import Loop from './Loop'
import If from './If'
import ValueToField from './ValueToField'
import MapString from './MapString'

//Not yet implemented
import Jsscript from './Nothing'
import While from './Nothing'
import Click from './Nothing'
import DeleteXpath from './Nothing'
import SetValue from './Nothing'
import DeleteField from './Nothing'
import Wait from './Nothing'
import Pyscript from './Nothing'
import GetLang from './Nothing'
import ExtractFromString from './Nothing'
import Folder from './Nothing'
import StoreThead from './Nothing'
import Output from './Nothing'
import FromCrawlers from './Nothing'
import ValueToFieldStats from './Nothing'
import PipeAll from './Nothing'
import Sitemap from './Nothing'

export const CommandsData = {
    fromCrawlers: {
        comp: FromCrawlers
    },
    extract: {
        comp: Extract,
        hasItemField: true
    },
    extractTable: {
        comp: ExtractTable,
        hasItemField: true
    },
    extractTableMulti: {
        comp: ExtractTable,
        hasItemField: true
    },
    mapString: {
        comp: MapString,
        hasItemField: true
    },
    filter: {
        comp: Filter
    },
    if: {
        comp: If
    },
    list: {
        comp: List,
        hasItemField: true
    },
    load: {
        comp: Load,
        hasItemField: true
    },
    loop: {
        comp: Loop
    },
    pipe: {
        comp: Pipe,
        hasItemField: true
    },
    pipeAll: {
        comp: PipeAll,
        hasItemField: false
    },
    sitemap: {
        comp: Sitemap,
        hasItemField: true
    },
    textProcessor: {
        comp: TextProcessor,
        hasItemField: true
    },
    output: {
        comp: Output,
    },
    jsscript: {
        comp: Jsscript
    },
    pyscript: {
        comp: Pyscript
    },
    while: {
        comp: While
    },
    click: {
        comp: Click
    },
    deleteXpath: {
        comp: DeleteXpath
    },
    deleteField: {
        comp: DeleteField,
        hasItemField: true
    },
    setValue: {
        comp: SetValue,
        hasItemField: true
    },
    wait: {
        comp: Wait
    },
    getLang: {
        comp: GetLang,
        hasItemField: true
    },
    valueToField: {
        comp: ValueToField,
        hasItemField: true
    },
    valueToFieldStats: {
        comp: ValueToFieldStats,
        hasItemField: true
    },
    storeThead: {
        comp: StoreThead,
        hasItemField: true
    },
    extractFromString: {
        comp: ExtractFromString,
        hasItemField: true
    },
    folder: {
        comp: Folder,
        hasItemField: false
    }
};