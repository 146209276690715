import React, { useState, useEffect, useMemo } from 'react'
import ClientProvider from '../components/Various/ClientProvider';
import CrawlerTreeAlias from '../components/SideBarRight/CrawlerTree/CrawlerTree';
import CommandsTreeAlias from '../components/SideBarRight/CommandsTree/CommandsTree';
import ProcConfigTree from '../components/SideBarRight/ProcConfigTree/ProcConfigTree';
import WordsLib from '../components/SideBarRight/WordsLib/WordsLib';
import Summery from '../components/SideBarRight/Summery/Summery';
import Jobs from '../components/SideBarRight/Jobs/Jobs';
import StdOutLog from '../components/SideBarRight/StdOutLog/StdOutLog';
import Batch from '../components/SideBarRight/Batch/Batch';

import Workspace from '../components/Workspace/Workspace'
import { makeid } from '../components/SideBarRight/CommandsTree/CommandsTree'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Button from "react-bootstrap/Button";

export default function CrawlerApp() {
  const [clientLoaded, setClientLoaded] = useState(null);

  const changeClientHandler = (clientNew) => {

    wsProvider.setClientSelected(clientNew)

  }


  const wsProvider = React.useContext(ClientProvider);
  wsProvider.ws.wsProvider = wsProvider
  wsProvider.wsRecv.wsProvider = wsProvider

  const [CommandsTree, setCommandsTree] = useState(null)

  const [CrawlerTree, setCrawlerTree] = useState(null)

  const [clientConfig, setClientConfig] = useState([]);
  const [stats, setStats] = useState({});
  const [itemsAll, setItemsAll] = useState({});
  const [loadFirstTableItem, setLoadFirstTableItem] = useState(false);

  const [stateVars, setStateVars] = useState(null)

  const [wordsLibGlobal, setWordsLibGlobal] = useState([])
  const [batchJobsStats, setBatchJobsStats] = useState({})
  const [baseFields, setBaseFields] = useState(null);
  const [sidePanelExpanded, setSidePanelExpanded] = useState(false)

  const clearBatchLog = () => {
    setBatchJobsStats({})
  }

  const handleBatchJobUpdate = (data) => {
    const batchJobNameId = data["batchJobName"] + data["batchJobId"]

    switch (data["level"]) {
      case "batchJob":
        wsProvider.setBatchJobsStats(x => {
          const newData = { ...x }
          newData[batchJobNameId] = {
            batchJobName: data["batchJobName"],
            batchJobId: data["batchJobId"],
            stats: data["stats"]
          }
          return newData
        })
        break
      case "batchJobItem":
        break
      case "batchJobItemCommand":
        break
    }
  }

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        setSidePanelExpanded(false)
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    // Load baseFields on startup
    wsProvider.ws.waitForSocketAndSend({
      action: "getBaseFields",
      options: {}
    }, (response) => {
      setBaseFields(response)
    })
  }, []);

  useEffect(() => {
    // Register Callback
    // Return is the cleanup function
    if (wsProvider.clientSelected && clientLoaded !== wsProvider.clientSelected) {
      console.log("Going to load ClientConfig:" + wsProvider.clientSelected, clientLoaded);

      //Load stateVars
      wsProvider.ws.waitForSocketAndSend({
        action: "getStateVars",
        options: {
          client: wsProvider.clientSelected,
          project_id: wsProvider.selectedProjectId
        }
      }, (response) => {
        if (response) {
          wsProvider.setStateVars(response)
        }
      })

      //Load Client Config
      wsProvider.ws.waitForSocketAndSend({
        action: "getClientConfig",
        options: {
          project: wsProvider.selectedProjectId,
          client: wsProvider.clientSelected
        }
      }, (response) => {
        setClientLoaded(wsProvider.clientSelected)
        setClientConfig(response)

        if (response[0] && response[0]["children"][0]) {
          const [crawlerSelected, setCrawlerSelected] = wsProvider.useStateVarGlobal("crawlerSelected")
          setCrawlerSelected(response[0]["children"][0].key)
        }

      })

      //Load stateGlobalVars1
      wsProvider.ws.waitForSocketAndSend({
        action: "getStateGlobalVars",
        options: {
          client: wsProvider.clientSelected,
          project_id: wsProvider.selectedProjectId
        }
      }, (response) => {
        if (response) {
          wsProvider.setStateGlobalVars(response)
        }
      })


      //Load global wordsLib
      wsProvider.ws.waitForSocketAndSend({
        action: "getWordsLibGlobal",
        options: {
        }
      }, (response) => {
        if (response) {
          setWordsLibGlobal(response)
        }
      })
    }
  }, [wsProvider.clientSelected])

  useEffect(() => {
    // Register Callback
    // Return is the cleanup function
    return wsProvider.registerBatchJobUpdateHandler("batchSidebar", handleBatchJobUpdate)
  }, [])


  const commandsConfig = useMemo(() =>
    wsProvider.getCrawlerSelected() && clientConfig.length > 0 && clientConfig[0].children.find(x => x.key === wsProvider.getCrawlerSelected())
      ? clientConfig[0].children.find(x => x.key === wsProvider.getCrawlerSelected()).commands
      : [],
    [wsProvider.getCrawlerSelected(), clientConfig]);

  const updateCommandsConfig = (commandsConfigNew) => {
    if (wsProvider.getCrawlerSelected() && clientConfig.length) {
      var clientConfigTemp = [...clientConfig]
      clientConfigTemp[0].children.find(x => x.key === wsProvider.getCrawlerSelected()).commands = commandsConfigNew
      setClientConfig(clientConfigTemp)
    }
  }

  const setStateVar = (scope, varName, value) => {
    var useId = null
    let temp = {
      ...stateVars
    }
    if (!(scope in temp)) {
      temp[scope] = {}
    }
    switch (scope) {
      case "crawler":
        useId = wsProvider.getCrawlerSelected()
        break
      case "command":
        useId = getCommandSelectedKey()
        break
    }
    if (!(useId in temp[scope]))
      temp[scope][useId] = {}
    temp[scope][useId][varName] = (typeof value === 'function') ? value(getStateVar(scope, varName)) : value
    setStateVars(temp)
  }

  const getStateVar = (scope, varName, defaultValue) => {
    var useId = null
    if (stateVars && scope in stateVars) {
      switch (scope) {
        case "crawler":
          useId = wsProvider.getCrawlerSelected()
          break
        case "command":
          useId = getCommandSelectedKey()
          break
      }
      if (useId && useId in stateVars[scope]) {
        let result = stateVars[scope][useId][varName]
        if (result !== undefined) {   //BEFORE:         if (result !== null && result !== undefined) {
          return result
        }
      }
    }
    return defaultValue
  }

  const getCommandSelected = () => {

    var commandKeyList = getStateVar("crawler", "commandSelected", [])
    var result = []
    var lastResult = null
    var searchIn = commandsConfig
    commandKeyList.forEach(key1 => {
      if (searchIn) {
        lastResult = searchIn.find(x => x["key"] === key1)
      }
      if (lastResult) {
        result.push(lastResult)
        searchIn = lastResult["children"]
      } else
        return result
    })
    return result
  }

  const getCommandSelectedKey = () => {

    var commandKeyList = getStateVar("crawler", "commandSelected", [])
    if (commandKeyList) {
      return commandKeyList[commandKeyList.length - 1]
    }
  }

  const setCommandSelected = (commandsList) => {
    if (commandsList[0]) {
      setStateVar("crawler", "commandSelected", commandsList.map(x => x["key"]))
    }
  }

  const useStateVar = (scope, varName, defaultValue) => {
    const f1 = getStateVar(scope, varName, defaultValue)
    const f2 = (value) => setStateVar(scope, varName, value)
    return [f1, f2]
  }

  const setWordsLib = (data, wordsLibScope) => {
    switch (wordsLibScope) {
      case "crawler":
        CrawlerTree.changeAttr("wordsLib", data)
        break
      case "command":
        CommandsTree.changeAttr("wordsLib", data)
        break
      case "client":
        setClientConfig([{
          ...clientConfig[0],
          "wordsLib": data
        }])
        break
      case "global":
        setWordsLibGlobal(data)
        //Also Save to System
        if (data.length > 0) {
          wsProvider.ws.waitForSocketAndSend({
            action: "setWordsLibGlobal",
            broadcastFunction: "setWordsLibGlobal",
            options: {
              wordsLib: data,
            }
          }, (response) => {
          })
        }
        break
    }
  }

  const getWordsLib = (wordsLibScope) => {
    let data = []
    switch (wordsLibScope) {
      case "crawler":
        data = CrawlerTree.getAttr("wordsLib")
        break
      case "client":
        data = clientConfig[0]["wordsLib"] ? clientConfig[0]["wordsLib"] : []
        break
      case "command":
        data = CommandsTree.getAttr("wordsLib")
        break
      case "global":
        data = wordsLibGlobal
        break
    }

    data = data ? data : []

    return data
  }

  const addWordsLib = (entrys, wordsLibScope) => {
    let newWordsLib = [
      ...getWordsLib(wordsLibScope),
      ...entrys
    ]
    setWordsLib(newWordsLib, wordsLibScope)
  }



  wsProvider.itemsAll = itemsAll
  wsProvider.setItemsAll = setItemsAll
  wsProvider.stats = stats
  wsProvider.setStats = setStats
  wsProvider.getCommandSelected = getCommandSelected
  wsProvider.setCommandSelected = setCommandSelected
  wsProvider.commandsConfig = commandsConfig
  wsProvider.updateCommandsConfig = updateCommandsConfig
  wsProvider.clientConfig = clientConfig
  wsProvider.setClientConfig = setClientConfig
  wsProvider.CommandsTree = CommandsTree
  wsProvider.setCommandsTree = setCommandsTree
  wsProvider.CrawlerTree = CrawlerTree
  wsProvider.setCrawlerTree = setCrawlerTree
  wsProvider.changeClientHandler = changeClientHandler
  wsProvider.stateVars = stateVars
  wsProvider.setStateVars = setStateVars
  wsProvider.setStateVar = setStateVar
  wsProvider.getStateVar = getStateVar
  wsProvider.useStateVar = useStateVar
  wsProvider.wordsLibGlobal = wordsLibGlobal
  wsProvider.setWordsLibGlobal = setWordsLibGlobal
  wsProvider.setWordsLib = setWordsLib
  wsProvider.getWordsLib = getWordsLib
  wsProvider.addWordsLib = addWordsLib
  wsProvider.loadFirstTableItem = loadFirstTableItem
  wsProvider.setLoadFirstTableItem = setLoadFirstTableItem
  wsProvider.batchJobsStats = batchJobsStats
  wsProvider.setBatchJobsStats = setBatchJobsStats
  wsProvider.clearBatchLog = clearBatchLog
  wsProvider.baseFields = baseFields



  wsProvider.toLog = (value, showTab = false) => {
    if (!wsProvider.setStdOutLog || value === undefined || value === null)
      return

    let newEntry = value["output"] ? value : {
      key: makeid(),
      output: [value]
    }

    wsProvider.setStdOutLog(data => {
      return [newEntry, ...data]
    })

    if (showTab) {
      setSidebarTabs1(2)
    }
  }
  wsProvider.clearLog = () => {
    if (!wsProvider.setStdOutLog)
      return
    wsProvider.setStdOutLog([])
  }

  wsProvider.crawlerTreeReady = () => wsProvider.CrawlerTree && wsProvider.clientConfig.length
  wsProvider.commandsTreeReady = () => wsProvider.CommandsTree && wsProvider.CommandsTree.getAttr("key")

  wsProvider.updateStats = (statsReceive, crawlerKey, client, deleteOld) => {

    wsProvider.setStats(stats => {
      var statsNew = {
        ...stats
      }

      if (client in stats) {
        statsNew[client] = {
          ...stats[client]
        }
      } else {
        statsNew[client] = {}
      }

      statsNew[client][crawlerKey] = {
        time: statsReceive["time"],
      }

      /* Delete Complete Crawler Based */
      if (deleteOld["type"] !== "crawler" && stats && stats[client] && crawlerKey in stats[client] && "commandsStats" in stats[client][crawlerKey]) {
        statsNew[client][crawlerKey]["commandsStats"] = { ...stats[client][crawlerKey]["commandsStats"] }
        /* Delete Command Based */
        if (deleteOld["type"] === "command") {
          if (deleteOld["command"] in statsNew[client][crawlerKey]["commandsStats"]) {
            statsNew[client][crawlerKey]["commandsStats"][deleteOld["command"]]["execs"] = []
          }
        }
      } else {
        statsNew[client][crawlerKey]["commandsStats"] = {}
      }

      var commandsStatsNew = statsNew[client][crawlerKey]["commandsStats"]

      if (deleteOld["type"] == "sourceList") {
        /* Delete sourceList-Based */
        Object.keys(commandsStatsNew).forEach(commandKey => {
          Object.keys(commandsStatsNew[commandKey]["execs"]).forEach(execId => {
            var item = commandsStatsNew[commandKey]["execs"][execId]["#source"]

            deleteOld["sourceList"].forEach((sourceListItem) => {
              if (sourceListItem["crawler"] === item["crawler"] && sourceListItem["crawlerI"] === item["crawlerI"] && sourceListItem["#"] === item["#"]) {
                delete commandsStatsNew[commandKey]["execs"][execId]
              }
            })
          })
        })
      }
      //Loop all commands
      const commandsStatsReceive = statsReceive["commandsStats"]
      Object.keys(commandsStatsReceive).forEach(commandKey => {
        const commandStatsReceive = commandsStatsReceive[commandKey]

        if (!(commandKey in commandsStatsNew)) {
          commandsStatsNew[commandKey] = {
            "execs": {}
          }
        }
        //Overwrite with new if it exists in commandStatsReceive
        Object.keys(commandStatsReceive["execs"]).forEach(execId => {

          const execItem = commandStatsReceive["execs"][execId]

          if (!(execId in commandsStatsNew[commandKey])) {
            commandsStatsNew[commandKey]["execs"][execId] = {}
          }
          commandsStatsNew[commandKey]["execs"][execId] = execItem
        })
      })

      return statsNew
    })
  }

  useEffect(() => {
    if (wsProvider.clientSelected === null || wsProvider.getCrawlerSelected() === null) {
      return
    }
    //Load clientConfig when clientSelected[localStorage] is definded and clientConfig doesn't match
    // console.log("STATE-VAR CHANGED")
    if (wsProvider.getCrawlerSelected()) {
      wsProvider.ws.waitForSocketAndSend({
        action: "saveStateVars",
        options: {
          client: wsProvider.clientSelected,
          project_id: wsProvider.selectedProjectId,
          stateVars: stateVars
        }
      }, () => {
        // console.log("STATE-VAR CHANGED: DONE")
        //console.log(wsProvider.getCrawlerSelected())
        //console.log(stateVars)
      })
    }
  }, [stateVars]);

  useEffect(() => {
    /* Load Stats when commmand is changed */
    const commandSelected = getStateVar("crawler", "commandSelected")
    if (commandSelected && CommandsTree) {
      CommandsTree.getCommandStats(true)
    }
  }, [getStateVar("crawler", "commandSelected")]);

  useEffect(() => {
    //Load clientConfig when clientSelected[localStorage] is definded and clientConfig doesn't match
    if (wsProvider.clientSelected && (wsProvider.clientLoaded !== wsProvider.clientSelected || clientConfig.length === 0)) {
      changeClientHandler(wsProvider.clientSelected)
    }
  }, []);

  useEffect(() => {
    //Load Commands Config on change of Crawler
    if (clientConfig[0] && clientConfig[0].children && wsProvider.getCrawlerSelected() && wsProvider.CrawlerTree) {
      setCommandSelected([])
      wsProvider.CrawlerTree.loadItems()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(wsProvider.clientSelected + wsProvider.getCrawlerSelected())]);    //crawlerKey is not unique between various clients

  useEffect(() => {
    //Select first Command when nothing is selected
    if (commandsConfig && !getCommandSelected().length) {
      setCommandSelected([commandsConfig[0]])
    }
  }, [commandsConfig, getCommandSelected().length]);

  const [sidebarTabs1, setSidebarTabs1] = wsProvider.useStateVarGlobal("sidebarTabs1", 0)
  const onSelect1 = (index) => {
    setSidebarTabs1(index)
  }

  const [sidebarTabs2, setSidebarTabs2] = wsProvider.useStateVarGlobal("sidebarTabs2", 0)
  const onSelect2 = (index) => {
    setSidebarTabs2(index)
  }


  /* LOG */
  const [stdOutLog, setStdOutLog] = useState([])

  wsProvider.stdOutLog = stdOutLog
  wsProvider.setStdOutLog = setStdOutLog

  if (!baseFields)
    return null

  return (
    <div className="App">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-10 CrawlerAppLeft">
            <div className="row">
              <Workspace />
            </div>
          </div>
          <div className={"col-sm-2 CrawlerAppRight" + (sidePanelExpanded? " fullscreen" : "")}>
            <Tabs
              selectedIndex={sidebarTabs1}
              onSelect={onSelect1}
            >
              <TabList>
                <Tab>Navigation</Tab>
                <Tab>Processing</Tab>
                <Tab className={"react-tabs__tab " + (stdOutLog.length > 0 ? "hasLogs" : "noLogs")}>Log ({stdOutLog.length})</Tab>
                <Tab>Jobs ({Object.keys(wsProvider.getJobsShow()).length})</Tab>
                <Button
                    className={"fullscreen"}
                    sytle="margin: 10px"
                    variant="light"
                    size={"sm"}
                    onClick={() => { setSidePanelExpanded(!sidePanelExpanded) }}>
                  <i className={sidePanelExpanded ? "bi-arrows-angle-contract" : "bi-arrows-angle-expand"}></i></Button>

              </TabList>
              <TabPanel forceRender={true} className="workspaceNavigation">
                <CrawlerTreeAlias />
                <CommandsTreeAlias />
                <Batch />
              </TabPanel>
              <TabPanel className="processingTabPanel">
                <Summery />
                <Batch />
                <Tabs
                  selectedIndex={sidebarTabs2}
                  onSelect={onSelect2}
                >
                  <TabList>
                    <Tab>WordsLib</Tab>
                    <Tab
                      disabled={wsProvider.commandsTreeReady() && wsProvider.CommandsTree.getAttr("className") == "textProcessor" ? false : true}
                    >ProcConfig</Tab>
                  </TabList>
                  <TabPanel className="">
                    <WordsLib />
                  </TabPanel>
                  <TabPanel className="">
                    <ProcConfigTree key={getStateVar("crawler", "commandSelected", [])} />
                  </TabPanel>
                </Tabs>
              </TabPanel>
              <TabPanel className="">
                <StdOutLog stdOutLog={stdOutLog} />
              </TabPanel>
              <TabPanel className="">
                <Jobs jobs={wsProvider.getJobsShow()} />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

function findAllByKey(obj, keyToFind) {
  return Object.entries(obj)
    .reduce((acc, [key, value]) => (key === keyToFind)
      ? acc.concat(value)
      : (typeof value === 'object')
        ? acc.concat(findAllByKey(value, keyToFind))
        : acc
      , [])
}
