import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  NavLink,
  useParams,
  Redirect
} from "react-router-dom";


import CrawlerApp from "./pages/CrawlerApp";
import Home from "./pages/Home";
import ClientOverview from "./pages/ClientOverview";
import ClientSelect from "./components/Various/Select/ClientSelect"
import ProjectPathSelect from "./components/Various/Select/ProjectPathSelect"

import ProjectProvider from './components/Various/ProjectProvider';
import ProjectPathProvider from './components/Various/ProjectPathProvider';
import ClientProvider from './components/Various/ClientProvider';

import WSSend from './websocket/WebsocketSend';
import WSRecv from './websocket/WebsocketRecv';
import useStickyState from './functions/useStickyState'
import { makeid } from './components/SideBarRight/CommandsTree/CommandsTree'


// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

import envs from './envs'
import { useAuth0 } from "@auth0/auth0-react";
import ProjectSelect from "./components/Various/Select/ProjectSelect";

function App({ authToken }) {

  const { websocketClientId } = useParams()

  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);

  const [clientSelected, setClientSelected] = useStickyState(null, "clientSelected");
  const [selectedProjectId, setSelectedProjectId] = useStickyState(null, "selectedProjectId");
  const [selectedProjectPath, setSelectedProjectPath] = useStickyState(null, "selectedProjectPath");

  // This would be nice
  // const [workspaceTabIndex, setWorkspaceTabIndex] = useStickyState(0, "workspaceTabIndex");
  const [stateGlobalVars, setStateGlobalVars] = useState({})
  const [callbacks, setCallbacks] = useState({})
  const [jobs, setJobs] = useState({})

  const [engineVersion, setEngineVersion] = useState(null)

  const [ws, setWs] = useState(() => {
    return new WSSend(envs.websocket_protocol + "://" + envs.host + ":" + envs.send_port + "/?websocketClientId=" + websocketClientId + '&authToken=' + authToken)
  });

  const [wsRecv, setWsRecv] = useState(() => {
    return new WSRecv(envs.websocket_protocol + "://" + envs.host + ":" + envs.recv_port + "/?websocketClientId=" + websocketClientId + '&authToken=' + authToken)
  });

  const [filesFromBackend, setFilesFromBackend] = useState({});

  const getEngineVersion = () => {
    ws.waitForSocketAndSend({
      action: "get_engine_version"
    }, (response) => {
      console.log("engine_version", response)
      setEngineVersion(response.data["engine_version"])
    })
  }
  const loadProjects = () => {
      ws.waitForSocketAndSend({
          action: "getProjects"
      }, (response) => {
          setProjects(response)
      })
  }

  useEffect(() => {
    //Load Clients-List on Startup
    if (projects && projects.length === 0) {
      loadProjects()
    }

    if (engineVersion === null) {
      getEngineVersion()
    }
  }, []);

  const refreshClients = () => {
    ws.waitForSocketAndSend({
      action: "getClientsConfig"
    }, (response) => {
      setClients(response)
    })
  }

  useEffect(() => {
    //Load Clients-List on Startup
    if (clients && clients.length === 0) {
      refreshClients()
    }
  }, []);

  const checkIsLoading = () => {
    const loading = Object.keys(getJobsShow()).length ? true : false

    document.getElementById("favicon").href = loading ? "/spinning.svg" : "/favicon.ico";

    return loading
  }

  const getJobsShow = () => {

    return Object.fromEntries(Object.entries(jobs).filter(([k, x]) => !(["saveStateVars", "saveStateGlobalVars"].includes(x["action"]))));

  }

  const useStateVarGlobal = (varName, defaultValue) => {
    const val = stateGlobalVars[varName] !== undefined ? stateGlobalVars[varName] : defaultValue

    const f2 = (f) => {
      setStateGlobalVars(data => {
        let newData = { ...data }
        if (typeof f === 'function') {
          newData[varName] = f(newData[varName])
        } else {
          newData[varName] = f
        }

        if (clientSelected !== null) {
          ws.waitForSocketAndSend({
            action: "saveStateGlobalVars",
            options: {
              client: clientSelected,
              project_id: selectedProjectId,
              stateGlobalVars: newData
            }
          }, (response) => { })
        }

        return newData
      })
    }
    return [val, f2]
  }



  const getCrawlerSelected = () => {
    return stateGlobalVars["crawlerSelected"] !== undefined ? stateGlobalVars["crawlerSelected"] : null
  }

  const setDocumentTitle = () => {
    const wsProvider = ws.wsProvider
    let title = ""

    if (wsProvider.crawlerTreeReady()) {
      title = title + wsProvider.clientSelected

      title = title + " | " + wsProvider.CrawlerTree.getAttr("title")

      if (wsProvider.CrawlerTree.getItems() !== null) {

        title = title + ", #" + wsProvider.CrawlerTree.getItems().length + ""
      }
    }

    document.title = title

  }

  const registerBatchJobUpdateHandler = (name, handlerFunction) => {
    setCallbacks(x => {
      const newCallbacks = { ...x }
      if (!("batchJobUpdate" in newCallbacks)) {
        newCallbacks["batchJobUpdate"] = {}
      }
      newCallbacks["batchJobUpdate"][name] = handlerFunction
      return newCallbacks
    })
    return function cleanup() {
      // Unregister Callback
      setCallbacks(x => {
        const newCallbacks = { ...x }
        if ("batchJobUpdate" in newCallbacks) {
          if (name in newCallbacks["batchJobUpdate"]) {
            delete newCallbacks["batchJobUpdate"][name]
          }
        }
        return newCallbacks
      })
    };
  }

  const getFileFromBackend = (filename, args = []) => {
    const fileNameHash = JSON.stringify([filename, args])
    if (fileNameHash in filesFromBackend && filesFromBackend[fileNameHash] !== "loading...") {
      return filesFromBackend[fileNameHash]
    } else {
      if (!(fileNameHash in filesFromBackend)) {
        setFilesFromBackend(filesFromBackend => {
          const newFilesFromBackend = {
            ...filesFromBackend
          }
          newFilesFromBackend[fileNameHash] = "loading..."
          return newFilesFromBackend
        })

        ws.waitForSocketAndSend({
          action: "getFileFromBackend",
          options: {
            filename,
            args
          }
        }, (response) => {
          setFilesFromBackend(filesFromBackend => {
            const newFilesFromBackend = {
              ...filesFromBackend
            }
            newFilesFromBackend[fileNameHash] = response
            return newFilesFromBackend
          })
        })
      }
    }
    return null
  }
  const {
    logout,
  } = useAuth0();
  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    });

  return (
    <ProjectProvider.Provider value={{
      projects: projects,
    }}>
      <ProjectPathProvider.Provider key={selectedProjectId} value={{
          selectedProjectId: selectedProjectId,
      }}>

          <ClientProvider.Provider key={selectedProjectPath} value={{
            websocketClientId: websocketClientId,
            authToken: authToken,
            callbacks: callbacks,
            setCallbacks: setCallbacks,
            registerBatchJobUpdateHandler,
            ws: ws,
            wsRecv: wsRecv,
            clients: clients,
            setClients: setClients,
            clientSelected: clientSelected,
            setClientSelected: setClientSelected,
            selectedProjectPath: selectedProjectPath,
            selectedProjectId: selectedProjectId,
            refreshClients: refreshClients,
            useStateVarGlobal: useStateVarGlobal,
            setStateGlobalVars: setStateGlobalVars,
            getCrawlerSelected: getCrawlerSelected,
            setWs: setWs,
            setWsRecv: setWsRecv,
            setDocumentTitle: setDocumentTitle,
            jobs: jobs,
            setJobs: setJobs,
            getJobsShow: getJobsShow,
            checkIsLoading: checkIsLoading,
            getFileFromBackend: getFileFromBackend,
          }}>
            <div>
              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto">
                      <li className="nav-item">
                        <NavLink to="/Home" exact className="nav-link" aria-current="page"
                                 activeClassName="active"><small>Home</small></NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/Crawler" className="nav-link" aria-current="page"
                                 activeClassName="active"><small>Crawler-App</small></NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/clients" className="nav-link" aria-current="page"
                                 activeClassName="active"><small>Clients</small></NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/" onClick={() => logoutWithRedirect()} className="nav-link" aria-current="page"
                                 activeClassName="active"><small>Logout</small></NavLink>
                      </li>
                    </ul>
                    <div className="version-label">
                        UI-Build: {envs.version}
                      </div>
                    <div className="version-label" onClick={() =>{
                      setEngineVersion('loading...')
                      getEngineVersion()
                    }}>
                        Engine-Build: {engineVersion}
                      </div>
                    <div className="environment-label">
                      <span>
                        Env: {envs.name}
                      </span>

                    </div>

                    <form className="d-flex">
                      <Route path="/">
                        <ProjectSelect selectedProjectId={selectedProjectId}
                                       setSelectedProjectId={setSelectedProjectId}/>
                        <ProjectPathSelect selectedProjectPath={selectedProjectPath}
                                           setSelectedProjectPath={setSelectedProjectPath}/>
                        <ClientSelect/>
                      </Route>
                    </form>
                  </div>
                </div>
              </nav>
              <Switch>
                <Route exact path="/Home">
                  <Home/>
                </Route>
                <Route path="/Crawler/:websocketClientId">
                  <CrawlerApp />
                </Route>
                <Route path="/clients">
                  <ClientOverview />
                </Route>
              </Switch>
            </div>
          </ClientProvider.Provider>
        </ProjectPathProvider.Provider>
    </ProjectProvider.Provider>
  );


}


export default function AppWrapper(props) {
  return (
    <BrowserRouter>
      <Route exact path="/">
        <Redirect to={"/Crawler/" + makeid()} />
      </Route>
      <Route exact path="/Crawler">
        <Redirect to={"/Crawler/" + makeid()} />
      </Route>
      <Route path="/Crawler/:websocketClientId">
        <App key={envs.name} {...props} />
      </Route>
    </BrowserRouter>
  )
}